
import {CommunicationViewModel} from "@/models/communications/CommunicationViewModel";
import {defineComponent, ref} from "vue";
import CommunicationModal from "@/components/modals/CommunicationModal.vue";
import moment from "moment";
import {getCommunicationType} from "@/models/enums/CommunicationType";
import {getCommunicationStatus} from "@/models/enums/CommunicationStatus";
import {CommunicationService} from "@/services/CommunicationService";
import Swal from "sweetalert2";
import CommunicationViewerModal from "@/components/modals/CommunicationViewerModal.vue";
import {bus} from "@/modules/eventBus";
import {useUser} from "@/store/pinia/userStore";
import {useApplication} from "@/store/pinia/applicationStore";
import {useToast} from "vue-toastification";
import {ProblemDetails} from "@/models/ProblemDetails";

export default defineComponent({
  setup() {
    const communicationModal = ref<InstanceType<typeof CommunicationModal>>();
    const communicationViewerModal = ref<InstanceType<typeof CommunicationViewerModal>>();
    const manageCommunication = (_communicationId: string) => {
      communicationModal.value?.invokeUpdate(_communicationId);
    }
    const viewCommunication = (_communication: CommunicationViewModel) => {
      communicationViewerModal.value?.toggleModal(_communication);
    }
    const userStore = useUser();
    const applicationStore = useApplication()
    const toast = useToast();
    return {
      communicationModal,
      manageCommunication,
      communicationViewerModal,
      viewCommunication,
      userStore,
      applicationStore,
      toast
    }
  },
  components: {CommunicationModal, CommunicationViewerModal},
  data() {
    return {
      communications: [] as Array<CommunicationViewModel>,
      communicationsLoading: true
    }
  },
  mounted() {
    //console.log('mounted')
    this.fetch()
    bus.on('modal:communications:refresh', this.fetch)
  },
  methods: {
    fetch() {
      this.communicationsLoading = true
      if (this.isOrganization) {
        CommunicationService.fetchOrganizationCommunications(this.organizationId).then(response => {
          this.communications = response.data
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.communicationsLoading = false
        })
      } else {
        CommunicationService.fetchProductionCommunications(this.productionId).then(response => {
          this.communications = response.data
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.communicationsLoading = false
        })
      }
    },
    remove(communication: CommunicationViewModel) {
      Swal.fire({
        title: "Are you sure?",
        confirmButtonText: 'Remove Communication',
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Keep Communication'
      }).then(response => {
        if (response.isConfirmed) {
          CommunicationService.removeCommunication(communication.communicationId).then(() => {
            this.toast.success('Communication Removed')
            this.fetch()
          }).catch(err => {
            let errorDetails = err.response.data as ProblemDetails
            this.toast.error(errorDetails.detail)
          })
        }
      })

    },
    accessCommunication(communication: CommunicationViewModel) {
      if (this.userStore.canManage(this.userStore.currentEntity)) {
        this.manageCommunication(communication.communicationId)
      } else {
        this.viewCommunication(communication)
      }
    },
    getStatus(status) {
      return getCommunicationStatus(status)
    },
    getFriendlyDateTime(date) {
      return moment(date).format('MMMM Do YYYY h:mm a').toString();
    },
    getCommunicationType(type) {
      return getCommunicationType(type)
    },
    getShortFriendlyDateTime(date: Date): string {
      return moment(date).format('MMM Do h:mm a').toString();
    }
  },
  computed: {
    isOrganization(): boolean {
      return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
    },
    organizationId(): string {
      return this.$route.params['organizationId'] as string
    },
    productionId(): string {
      return this.$route.params['productionId'] as string
    },
  },
  created() {
    this.$watch(
        () => this.$route.params,
        () => {
          if (this.applicationStore.isEntityMode) {
            this.fetch()
          }
        }
    )
  },
})
